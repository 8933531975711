.beforeborder {
    position:relative;
    overflow:hidden;
}

.beforeborder:before{
    content:'';
    background:#7f80a0;
    position:absolute;
    top:-10px;
    left:0;
    width:100%;
    height:15px;
    border-radius:0 0 0% 0%;
    opacity: 0.5;
}
.beforeborder:after {
    top:auto;
    bottom:-10px;
    border-radius:0% 0% 0 0 ;
}